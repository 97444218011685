<template>
  <div class="row">
    <div class="col-sm-8" style="text-align: left;">
      <p><span>Merging schools combines users and information for the two schools</span></p>
    </div>
    <div class="col-sm-3">
      <div class="row">
        <div style="text-align: left;">
          <label>Search</label>
          <input v-model="searchText" type="text" spellcheck="false" placeholder="Name, Email, Organization"/>
        </div>
        <div style="text-align: right;">
          <button type="button" class="button btn-primary bi-search" @click="getSchoolsSearch">Search</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="row">
      <h3 style="font-weight: bold;">Merging</h3>
      <div class="col-sm " style="text-align: left;">
        <h6>Name: {{ schoolToRemove.schoolName }}</h6>
        <h6>CDN: {{ schoolToRemove.schoolCdn }}</h6>
        <h6>ESU: {{ schoolToRemove.schoolEsu }}</h6>
        <h6>District: {{ schoolToRemove.schoolDistrict }}</h6>
      </div>
      <div class="col-sm " style="text-align: left;">
        <h6>Address: {{ schoolToRemove.primarySchoolAddress }}</h6>
        <h6>Phone: {{ schoolToRemove.schoolPhoneNumber }}</h6>
      </div>
    </div>
  </div>
  <div class="container-lg mt-2" v-if="showResults">
    <table id="districtTable" class="table table-bordered table-striped">
      <thead>
      <tr>
        <th v-for="(value, name, index) in fieldHeaders" :key="index" @click="sortTable(name)">{{ value }}
          <i :class="name === prevSearchCol && storedReverse ? 'bi bi-sort-up' : 'bi bi-sort-down'"
             aria-label='Sort Icon'></i>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in updatedList" :key="item">
        <td v-for="(value, name, index) in fieldHeaders" :key="index">
          <router-link v-if="name === 'schoolName'" :to="{name: 'MergeSchools', params: {idRemovingSchool: idRemovingSchool, idKeepingSchool: item['idSchool']}}">{{item[name]}}</router-link>
          <p v-else>{{ item[name]}}</p>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import axios from "axios";
import {getSchool, searchSchools} from "@/Helpers/ApiCalls/SchoolAPICalls";
import {sortBy} from "lodash/collection";

export default {
  name: "SchoolMergeSearch",
  props: {
    idRemovingSchool: Number
  },
  setup(props) {
    const searchText = ref("");
    const isLoaded = ref(false);
    const sort = ref(false);
    const updatedList = ref(false);
    const prevSearchCol = ref("");
    const storedReverse = ref(false);
    const schools = ref([]);
    const schoolToRemove = ref({});

    const fieldHeaders = ref({
      schoolName: "Name",
      schoolCdn: "CDN",
      primarySchoolAddress: "Address",
      schoolPhoneNumber: "Phone",
      schoolDistrict: "District",
      schoolEsu: "ESU",
      schoolCity: "City",
      schoolZipCode: "ZIP"
    })

    onBeforeMount(() => {
      getRemovingSchool();
    })

    async function getRemovingSchool() {
      isLoaded.value = false;
      await axios.get(getSchool(props.idRemovingSchool), {
        params: {
          showUserCount: false
        }
      }).then((result) => {
            if (result.status === 200) {
              schoolToRemove.value = result.data
            }
          }).finally(() => {
            isLoaded.value = true;
          })
    }

    async function getSchoolsSearch() {
      await axios.get(searchSchools(), {
        params: {
          searchText: searchText.value
        }
      }).then((result) => {
        if (result.status === 200) {
          schools.value = result.data;
        }
      }).finally(() => {
        updatedList.value = schools.value.filter(school => school.idSchool !== parseInt(props.idRemovingSchool));
      })
    }

    const sortTable = (col) => {
      sort.value = true;
      if (col !== prevSearchCol.value) {
        storedReverse.value = false;
        updatedList.value = sortBy(schools.value, col);
      } else {
        storedReverse.value = !storedReverse.value;
        updatedList.value = updatedList.value.reverse();
      }
      prevSearchCol.value = col;
    }

    const showResults = computed(() => {
      return schools.value.length > 0;
    })

    return {
      schools,
      schoolToRemove,
      isLoaded,
      searchText,
      updatedList,
      showResults,
      fieldHeaders,
      prevSearchCol,
      storedReverse,
      sortTable,
      getSchoolsSearch
    }
  }
}
</script>

<style scoped>

</style>